<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import Multiselect from "vue-multiselect";
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { apiRequest } from "@/helpers/api-call";
//import { format } from "date-fns";
import router from "@/router/index.js";

/**
 * Orders Component
 */
export default {
  components: {
    Layout,
    PageHeader,
    Multiselect,
    ckeditor: CKEditor.component,
  },
  data() {
    return {
      title: "Liste des domiciles",
      items: [
        {
          text: "Dashboard",
        },
        {
          text: "liste",
          active: true,
        },
      ],
      tyMedia: [
        {
          name: "Texte",
          value: "text",
        },
        {
          name: "Image",
          value: "image",
        },
        {
          name: "Vidéo",
          value: "video",
        },
      ],
      tyMessage: [
        {
          name: "Notification",
          value: 0,
        },
        {
          name: "Mail",
          value: 1,
        },
        {
          name: "Notification + Mail",
          value: 2,
        },
      ],

      url_link: "",
      userTypeSelect: "",
      messageSelect: null,
      mediaSelect: null,
      messageContent: [
        {
          sujet: "",
          message: "",
          userId: "",
        },
      ],
      value: null,
      value1: null,

      idUser: 0,
      dataUser: [],
      dataUserD: [],
      rowsdataUser: 0,
      rowsdataUserD: 0,
      nbreUser: "",
      totalRows: 1,
      currentPage: 1,
      perPage: 100,
      pageOptions: [100, 250, 500],
      filter: null,
      filterOn: [],
      sortBy: "orderid",
      sortDesc: false,
      fields: [
        { key: "username", sortable: true, label: "Nom et Prénom" },
        { key: "tel", sortable: true, label: "Téléphone" },
        { key: "domicile", sortable: true, label:"Domicile"   },
        { key: "workPlaceName", sortable: true, label: "Lieu de  travail"  },
        //{ key: "type", sortable: true, label: "Type" },
        { key: "trajet", sortable: true, label: "Trajets Publiés" },
        { key: "reservation", sortable: true, label: "Réservations" },

        { key: "action", label: "Actions" },
      ],

      showSucess: false,
      showEchec: false,
      showSucessEdit: false,
      showEchecEdit: false,
      userToUse: 0,
      userTypeLocal: null,
      showMessage: false,
      showMessageMail: false,
      editor: ClassicEditor,
      editorData: "",
      deleteModal: false,
      startValue: 1,
      endValue: 500,
      totalValue: "0",
      userChargeLoader: false,
      newDataDomicile: [],
      newStart: false,
      recherche:""
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.dataUser.length + this.dataUserD.length;
    },
  },
  async mounted() {
    this.init();
    this.userTotal();
  },
  methods: {
    async init() {
      //Chargement des données
      const data = await apiRequest(
        "GET",
        "admin/users-domicile",
        undefined,
        false
      );
      if (data && data.data) {
        const formattedTable = data.data.map((user) => {
          var nom = user.lastname == "" ? "Nom" : user.lastname;
          var prenom = user.firstname == "" ? "Prénom" : user.firstname;
          return {
            id: user.id,
            username: nom + " " + prenom,
            tel: user.tel && user.tel != "" ? user.indicatifTel + user.tel : "",
            domicile: user.adresse,
            trajet: user.nombreTrajet,
            reservation: user.nombreReser,
            workPlaceName: user.workPlaceName,
          };
        });
        this.dataUser = formattedTable;
        console.log("this.dataUser",data.data)
        this.rowsdataUser = formattedTable.length;
        this.totalRows = formattedTable.length;
      }
    },
    async addMessage() {
      this.showMessage = false;
      this.showMessageMail = false;

      const data = await apiRequest(
        "POST",
        "admin/message-user",
        {
          idUser: this.messageContent.userId,
          typeMessage: this.messageSelect.value,
          subject: this.messageContent.sujet,
          text: this.messageContent.message,
          media_type: this.mediaSelect.value, //this.tyMedia.value ? this.tyMedia.value : "",
          url: this.url_link,
        },
        false
      );
      //console.log("data.data:", data);
      if (data && data.data) {
        this.$toast.success("Message envoyé avec succès !!!", {
  position: "bottom-right",
  timeout: 2000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: true,
  closeButton: "button",
  icon: true,
  rtl: false
});
        this.init();
      } else {
        this.$toast.success("L'envoi du message a échoué. Réessayez", {
  position: "bottom-right",
  timeout: 2000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: true,
  closeButton: "button",
  icon: true,
  rtl: false
});


        this.init();
      }
    },
    messageShow(row, typeMessage) {
      this.messageSelect = typeMessage;

      if (typeMessage.value == 0) this.showMessage = true;
      if (typeMessage.value == 1) this.showMessageMail = true;
      this.messageContent.userId = row.id;
    },

    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    goToDetail(row) {
      router.push(`/user/user_info/${row.id}`);
    },

    async actif(page, limit) {
      //Chargement des données

      const data = await apiRequest(
        "GET",
        "admin/users-domicile?page=" + page + "&limit=" + limit,
        undefined,
        false
      );
      if (data && data.data) {
        const formattedTable = data.data.map((user) => {
          var nom = user.lastname == "" ? "Nom" : user.lastname;
          var prenom = user.firstname == "" ? "Prénom" : user.firstname;
          return {
            id: user.id,
            username: nom + " " + prenom,
            tel: user.tel && user.tel != "" ? user.indicatifTel + user.tel : "",
            domicile: user.adresse,
            trajet: user.nombreTrajet,
            reservation: user.nombreReser,
          };
        });
        this.newDataDomicile = formattedTable;
        this.rowsdataUser = formattedTable.length;
        this.totalRows = formattedTable.length

        this.userChargeLoader = false;
      }
    },

    async userTotal() {
      const datatotal = await apiRequest(
        "GET",
        "admin/user-total",
        undefined,
        false
      );
      if (datatotal && datatotal.data) {
        this.totalValue = datatotal.data.total;
        this.totalRows = datatotal.data.total;
      }
    },

    async userSearch() {
      console.log(this.recherche);
      this.userChargeLoader = true;
      const dataSearch = await apiRequest(
        "POST",
        "admin/user-recherche?page="+ this.startValue + "&limit=" + this.endValue,

        { mots: this.recherche },



        false
      );
      if (dataSearch && dataSearch.data) {
        const formattedTable = dataSearch.data.map((user) => {
          var nom = user.lastname == "" ? "Nom" : user.lastname;
          var prenom = user.firstname == "" ? "Prénom" : user.firstname;
          return {
            id: user.id,
            username: nom + " " + prenom,
            tel: user.tel && user.tel != "" ? user.indicatifTel + user.tel : "",
            domicile: user.adresse,
            trajet: user.nombreTrajet,
            reservation: user.nombreReser,
          };
        });
        this.newDataDomicile = formattedTable;
        this.totalRows = formattedTable.length
        this.userChargeLoader = false;
      }
    },

    decrement() {
      this.userChargeLoader = true;

      this.startValue -= 500;
      this.endValue -= 500;
      console.log("userChargeLoader", this.userChargeLoader);
      this.actif(this.startValue, this.endValue);
    },

    increment() {
      this.userChargeLoader = true;
      this.startValue += 500;
      this.endValue += 500;
      console.log("userChargeLoader", this.userChargeLoader);
      this.actif(this.startValue, this.endValue);
    },
    incrementToLast() {
      this.userChargeLoader = true;
      this.startValue = this.totalValue - 500;
      this.endValue = this.totalValue;
      console.log("userChargeLoader", this.userChargeLoader);
      this.actif(this.startValue, this.endValue);
    },
    firstVal() {
      this.userChargeLoader = true;
      this.startValue = 1;
      this.endValue = 500;
      console.log("userChargeLoader", this.userChargeLoader);
      this.actif(this.startValue, this.endValue);
    },

   


  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">

          <div class="containerDot" v-if="dataUser.length == 0">
            <span class="loading">Chargement en cours... </span>
            <!--  <div class="dots">
              <div class="dot dot-1"></div>
              <div class="dot dot-2"></div>
              <div class="dot dot-2"></div>
            </div> -->
          </div>
          <div
            class="d-flex align-items-center otherThing"
            v-if="userChargeLoader === true"
          >
            <span class="loading">Chargement en cours...</span>
            <div aria-hidden="true"></div>
          </div>
          <div class="card-body pt-0">
          
            <div
              class="row d-flex justify-content-between align-items-center p-2"
            >
              <div>
                <h4 class="card-title mt-4">
                 Total de domiciles : {{ totalRows }}
                </h4>
              </div>
              <transition mode="out-in">
                <div
                  class="dataTables_paginate paging_simple_numbers float-right"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <li>
                      <span class="interval"> {{ startValue }}</span> -
                      <span class="interval">{{ endValue }}</span> sur
                      {{ totalValue }}
                    </li>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <li>
                      <button
                        class="myBouton"
                        @click="firstVal()"
                        :disabled="startValue == 1"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Prem"
                      >
                        <ion-icon name="play-skip-back-outline"></ion-icon>
                      </button>
                      <button
                        type="button"
                        class="myBouton"
                        @click="decrement()"
                        :disabled="endValue == 500"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Préc"
                      >
                        <ion-icon name="chevron-back-outline"></ion-icon>
                      </button>
                      <button
                        class="myBouton"
                        @click="increment()"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Suiv"
                        :disabled="endValue >= totalValue"
                      >
                        <ion-icon name="chevron-forward-outline"></ion-icon>
                      </button>
                      <button
                        class="myBouton"
                        @click="incrementToLast()"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Dern"
                        :disabled="endValue >= totalValue"
                      >
                        <ion-icon name="play-skip-forward-outline"></ion-icon>
                      </button>
                    </li>
                  </ul>
                </div>
              </transition>
            </div>

            <div class="row d-flex justify-content-between align-items-center  mt-4">
              <div class="p-3">
              
              </div>
          
              <!-- Search -->
              <div class="inputCol p-3">
                <div class="input ">
                    <input
                      type="search"
                      id="serchInput"
                      placeholder="Rechercher..."
                      v-model="recherche"
                      @keyup.enter="userSearch"
                    />
                  </div>
      
              </div>
              <!-- End search -->
            </div>
            <div class="table-responsive">
              <b-table
                :items="newDataDomicile.length!=0 ? newDataDomicile:dataUser"
                style="cursor: pointer"
                class="table-centered"
                :fields="fields"
                responsive="sm"
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
                @row-clicked="goToDetail"
              >
                <template v-slot:cell(badge)="row">
                  <div
                    class="py-2 d-flex justify-content-center"
                    :class="{
                      'fa fa-star': `${row.value}` === 'false',
                      'fa fa-star badge-soft-warning':
                        `${row.value}` === 'true',
                    }"
                  ></div>
                </template>
                <template v-slot:cell(action)="row">
                  <a
                    href="javascript:void(0);"
                    class="text-warning mr-1"
                    @click="
                      messageShow(row.item, {
                        name: 'Notification',
                        value: 0,
                      })
                    "
                    v-b-tooltip.hover
                    title="Notification"
                  >
                    <i class="fas fa-bell"></i>
                  </a>
                  <a
                    href="javascript:void(0);"
                    class="text-success"
                    @click="messageShow(row.item, { name: 'Mail', value: 1 })"
                    v-b-tooltip.hover
                    title="Mail"
                  >
                    <i class="fas fa-envelope"></i>
                  </a>
                </template>

                <template v-slot:cell(tel)="row" style="text-align: center">
                  <a
                    :href="'https://wa.me/' + row.value"
                    class="text-success"
                    v-b-tooltip.hover
                    title="Envoyer un message whatsapp"
                    target="_blank"
                  >
                    {{ row.value }}
                  </a>
                </template>
              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <!--   <div
                  class="dataTables_paginate paging_simple_numbers float-right"
                >
                  <ul class="pagination pagination-rounded mb-0">
                
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="rows"
                      :per-page="perPage"
                      :aria-controls="trajet"
                    ></b-pagination>
                  </ul>
                </div> -->
                <transition mode="out-in">
                <div
                  class="dataTables_paginate paging_simple_numbers float-right"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <li>
                      <span class="interval"> {{ startValue }}</span> -
                      <span class="interval">{{ endValue }}</span> sur
                      {{ totalValue }}
                    </li>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <li>
                      <button
                        class="myBouton"
                        @click="firstVal()"
                        :disabled="startValue == 1"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Prem"
                      >
                        <ion-icon name="play-skip-back-outline"></ion-icon>
                      </button>
                      <button
                        type="button"
                        class="myBouton"
                        @click="decrement()"
                        :disabled="endValue == 500"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Préc"
                      >
                        <ion-icon name="chevron-back-outline"></ion-icon>
                      </button>
                      <button
                        class="myBouton"
                        @click="increment()"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Suiv"
                        :disabled="endValue >= totalValue"
                      >
                        <ion-icon name="chevron-forward-outline"></ion-icon>
                      </button>
                      <button
                        class="myBouton"
                        @click="incrementToLast()"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Dern"
                        :disabled="endValue >= totalValue"
                      >
                        <ion-icon name="play-skip-forward-outline"></ion-icon>
                      </button>
                    </li>
                  </ul>
                </div>
              </transition>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- ENVOYER LE MESSAGE NOTIF-->
    <b-modal v-model="showMessage" title="Nouveau Message" centered>
      <form>
        <div class="form-group">
          <label class="control-label">Choisir le type de message:</label>
          <multiselect
            v-model="messageSelect"
            :options="tyMessage"
            label="name"
            track-by="value"
          ></multiselect>
        </div>
        <div class="form-group">
          <input
            type="text"
            class="form-control"
            placeholder="Sujet"
            v-model="messageContent.sujet"
          />
        </div>
        <div class="form-group mb-2">
          <ckeditor
            v-model="messageContent.message"
            :editor="editor"
            aria-placeholder="Votre message"
          ></ckeditor>
          <!--   
          <textarea
            v-model="messageContent.message"
            placeholder="Votre message"
            class="form-control"
            id=""
            cols="30"
            rows="10"
          ></textarea>-->
        </div>
        <div class="form-group">
          <multiselect
            v-model="mediaSelect"
            :options="tyMedia"
            placeholder="Image, vidéo"
            label="name"
            track-by="value"
          ></multiselect>
        </div>
        <div class="form-group">
          <input
            type="text"
            class="form-control"
            v-model="url_link"
            placeholder="Lien du média"
          />
        </div>
      </form>
      <template v-slot:modal-footer>
        <b-button variant="secondary" @click="showMessage = false"
          >Fermer</b-button
        >
        <b-button variant="primary" @click="addMessage">
          Envoyer
          <i class="fab fa-telegram-plane ml-1"></i>
        </b-button>
      </template>
    </b-modal>
    <!-- -->

    <!-- ENVOYER LE MESSAGE MAIL-->
    <b-modal v-model="showMessageMail" title="Nouveau Message" centered>
      <form>
        <div class="form-group">
          <label class="control-label">Choisir le type de message:</label>
          <multiselect
            v-model="messageSelect"
            :options="tyMessage"
            label="name"
            track-by="value"
          ></multiselect>
        </div>
        <div class="form-group">
          <input
            type="text"
            class="form-control"
            placeholder="Sujet"
            v-model="messageContent.sujet"
          />
        </div>
        <div class="form-group mb-2">
          <ckeditor
            v-model="messageContent.message"
            :editor="editor"
            aria-placeholder="Votre Message"
          ></ckeditor>
        </div>
        <div class="form-group">
          <multiselect
            v-model="mediaSelect"
            :options="tyMedia"
            placeholder="Image, vidéo"
            label="name"
            track-by="value"
          ></multiselect>
        </div>
        <div class="form-group">
          <input
            type="text"
            class="form-control"
            v-model="url_link"
            placeholder="Lien du média"
          />
        </div>
      </form>
      <template v-slot:modal-footer>
        <b-button variant="secondary" @click="showMessage = false"
          >Fermer</b-button
        >
        <b-button variant="primary" @click="addMessage">
          Envoyer
          <i class="fab fa-telegram-plane ml-1"></i>
        </b-button>
      </template>
    </b-modal>
    <!-- -->
  </Layout>
</template>
<style>
  thead {
  background: #3AAA35;
}thead div{
  color:#fff;
}

.input {
  right: 13px;
  position: absolute;
  display: flex;
  justify-content: center;
  width: 200px;
  height: 35px;
  bottom: 0;
  flex: 60%;

  box-shadow: 0px 3px 6px 0px rgb(0 0 0 / 10%), 0px 1px 3px 0px rgb(0 0 0 / 8%);
  background: #ffffff;
}

.input input {
  padding: 3px 3px 3px 8px;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  font-size: 18px;
  color: #495057;
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 500;
}
.input input:focus {
  border: 3px solid #3aaa35;
}
.inputCol {
  position: relative;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #252b3b;
  border-color: #252b3b;
}


/*   Pagination System   */

.loading {
  font-size: 2rem;
  color: hsl(0, 0%, 12%);
}

.loading {
  font-weight: bold;
  font-size: 1.001rem;
  width: 100%;
  white-space: nowrap;
  color: hsl(0, 0%, 12%);
  text-align: center;
  display: inline-block;
  margin: 0 auto !important;
}
.otherThing {
  background-color: #f9edbe;
  border: 1px solid #f0c36d;
  width: 250px;
  height: 40px;
  margin-left: auto;
  margin-right: auto;
  margin-top: -50px;
}
.containerDot {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f9edbe;
  border: 1px solid #f0c36d;
  font-size: 2rem;
  color: hsl(0, 0%, 12%);
  width: 250px;
  height: 40px;
  position: absolute;
  top: -16%;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.dots {
  position: relative;
  width: 100px;
  height: 50px;
  transform: scale(0.25, 0.25);
  margin-left: -10px;
}
.dot {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 50%;
  transform: translate(0, -40%);
  opacity: 0;
  border-radius: 50%;
  background-color: hsl(0, 0%, 12%);
}

.dot-1 {
  animation: animation-dots 1.5s infinite linear 1s;
}
.dot-2 {
  animation: animation-dots 1.5s infinite linear 0.5s;
}
.dot-3 {
  animation: animation-dots 1.5s infinite linear;
}

@keyframes animation-dots {
  0% {
    left: -100px;
    opacity: 0;
  }
  20% {
    left: 0px;
    opacity: 1;
  }
  80% {
    left: 80px;
    opacity: 1;
  }
  100% {
    opacity: 0;
    left: 150px;
  }
}

.rowBase {
  position: relative;
}

ion-icon {
  cursor: pointer;
}

.myBouton {
  background: transparent;
  text-align: center;
  outline: none;
  border: none;
}

.containerDot {
  z-index: 100;
}

.paging_simple_numbers {
  background: #3AAA35;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  padding: 10px 30px;

  box-shadow: 6px 6px 10px -1px rgba(0, 0, 0, 0.15),
    -6px -6px 10px -1px rgba(255, 255, 255, 0.7);
} .paging_simple_numbers ul li{
  color:white!important;
}


.paging_simple_numbers ul li button{
  color:#fff!important;
}
</style>